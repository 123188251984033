import React from 'react';

const Team = () => (
  <div className="aboutContainer mb-5">
    <div className="row aboutUs justify-content-center">
      <div className="col-12 initSection containerA">
        <h1 className="titleMax blueDark mb-0">
          Meet the team
        </h1>
        <h4 className="txt blueAN mt4 alignTC mb-0">
          We are passionate about empowering small businesses,
          <br className="onlyDesktop" />
          and we believe that the best work is born from diligence and customer focus.
        </h4>
        <h2 className="genericTitle blueDark mb-0 alignTC">
          Driven by your success
        </h2>
      </div>
      <div className="picturesTeam row justify-content-center">
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/mau.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Mau</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">CEO</p>
            <img className="logo" src="assets/images/logo_white.png" alt="Mau" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/montse.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Montse</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">
              Customer
              <br />
              Success
            </p>
            <img className="logo" src="assets/images/logo_white.png" alt="Montse" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/frank.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Frank</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">Product</p>
            <img className="logo" src="assets/images/logo_white.png" alt="Frank" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/alfred.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Alfred</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">
              Customer
              <br />
              Success
            </p>
            <img className="logo" src="assets/images/logo_white.png" alt="Alfred" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/hec.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Hec</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">Product</p>
            <img className="logo" src="assets/images/logo_white.png" alt="Hec" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/vic.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Vic</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">
              Customer
              <br />
              Success
            </p>
            <img className="logo" src="assets/images/logo_white.png" alt="Vic" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/alex.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">Alex</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">Product</p>
            <img className="logo" src="assets/images/logo_white.png" alt="Alex" />
          </div>
          <div className="backMember" />
        </div>
        <div className="memberTeam col-4 col-lg-1 pt2 pb2">
          <div className="memberInfo">
            <img
              className="img-member"
              src="assets/images/jose.png"
              alt="image_team"
            />
            <p className="gray txt name mt2 mb2">José</p>
            <span className="line" />
            <p className="white txt position mt2 alignTC">Product</p>
            <img className="logo" src="assets/images/logo_white.png" alt="Jose" />
          </div>
          <div className="backMember" />
        </div>
      </div>
    </div>
  </div>
);

export default Team;
