/* eslint-disable max-len */
import React from 'react';

const Cover = () => (
  <svg className="phoneCover " xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="453" height="676" viewBox="0 0 453 676">
    <defs>
      <filter id="a" width="104.2%" height="102.1%" x="-2.1%" y="-1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <rect id="c" width="288" height="576" rx="40" />
      <filter id="b" width="160.8%" height="130.4%" x="-21.7%" y="-10.9%" filterUnits="objectBoundingBox">
        <feOffset dx="25" dy="25" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="25" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.463567183 0 0 0 0 0.463567183 0 0 0 0 0.466836735 0 0 0 0.495782382 0" />
      </filter>
      <filter id="d" width="142.7%" height="121.4%" x="-12.7%" y="-6.3%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="11" />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.1 0" />
      </filter>
      <rect id="f" width="253" height="545" x="18" y="16" rx="25" />
      <filter id="e" width="111.9%" height="105.5%" x="-5.9%" y="-2.8%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.0480355525 0" />
      </filter>
      <rect id="h" width="221" height="113" rx="24" />
      <filter id="g" width="114.5%" height="128.3%" x="-7.2%" y="-12.4%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <rect id="j" width="221" height="113" rx="24" />
      <filter id="i" width="114.5%" height="128.3%" x="-7.2%" y="-12.4%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <rect id="l" width="221" height="113" rx="24" />
      <filter id="k" width="114.5%" height="128.3%" x="-7.2%" y="-12.4%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(90 25)">
        <rect width="288" height="576" x="1" y="2" fill="#000" filter="url(#a)" opacity=".197" rx="40" />
        <use fill="#000" filter="url(#b)" xlinkHref="#c" />
        <use fill="#F2F5F8" xlinkHref="#c" />
        <use fill="#000" filter="url(#d)" xlinkHref="#c" />
        <g>
          <use fill="#000" filter="url(#e)" xlinkHref="#f" />
          <use fill="#F7F7F7" xlinkHref="#f" />
        </g>
      </g>
      <g transform="translate(124 158)">
        <use fill="#000" filter="url(#g)" xlinkHref="#h" />
        <use fill="#FFF" xlinkHref="#h" />
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500">
          <tspan x="16" y="33">Mau S</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="144" y="93">Paid $100</tspan>
        </text>
        <path fill="#E3E3E3" d="M16 49h189v-1H16z" />
        <text fill="#9B9B9B" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="16" y="77">Residential</tspan>
          {' '}
          <tspan x="16" y="93">04:00 PM</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="116.878" y="33">Completed</tspan>
        </text>
        <circle cx="200" cy="30" r="5" fill="#2d3ee0" />
      </g>
      <g transform="translate(124 456)">
        <use fill="#000" filter="url(#i)" xlinkHref="#j" />
        <use fill="#FFF" xlinkHref="#j" />
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500">
          <tspan x="16" y="33">Raymond J</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="149.451" y="93">Paid $90</tspan>
        </text>
        <path fill="#E3E3E3" d="M16 49h189v-1H16z" />
        <text fill="#9B9B9B" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="16" y="77">Estimate</tspan>
          {' '}
          <tspan x="16" y="93">03:30 PM</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="116.878" y="33">Completed</tspan>
        </text>
        <circle cx="200" cy="30" r="5" fill="#2d3ee0" />
      </g>
      <g transform="translate(10 287)" className="blockOnWay">
        <use fill="#000" filter="url(#k)" xlinkHref="#l" />
        <use fill="#FFF" xlinkHref="#l" />
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500">
          <tspan x="16" y="33">Leo L</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="130.64" y="93">Paid $45.40</tspan>
        </text>
        <path fill="#E3E3E3" d="M16 49h189v-1H16z" />
        <text fill="#9B9B9B" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="16" y="77">Commercial</tspan>
          {' '}
          <tspan x="16" y="93">10:00 PM</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="113.875" y="33">On the way</tspan>
        </text>
        <circle className="circleCover" cx="200" cy="30" r="5" fill="#07F2BB" />
      </g>
      <path fill="#E3E3E3" d="M124 101h221v1H124z" />
      <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500" transform="translate(40 40)">
        <tspan x="175.412" y="38">June</tspan>
      </text>
      <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(10 25)">
        <tspan x="118" y="113">Monday 29</tspan>
      </text>
      <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(10 25)">
        <tspan x="118" y="411">Tuesday 30</tspan>
      </text>
    </g>
  </svg>
);

export default Cover;
