import React from 'react';

import Cover from '../svgs/cover';
import Bookings from '../svgs/bookings';
import Chat from '../svgs/chat';
import Payments from '../svgs/payments';
import TeamManagement from '../svgs/team_management';

const Home = () => (
  <section id="layout-content">
    <div>
      <div className="block_movers mt9 pt2 pb2 bg_blueAN">
        <div style={{ 'text-align': 'center' }} className="contentBlockM">
          <a
            style={{
                color: 'white',
                '!important;text-decoration': 'none',
                display: 'inline-block',
            }}
            href="#kf"
            className="genericTitle banner mb-0 white"
          >
            Astro is joining Konfío!
          </a>
        </div>
      </div>
      <div className="cover contSectionHeight">
        <div className="containerA row">
          <div className="containerTitle col-sm-12 col-lg-8 alignTL">
            <h1 className="titleMax blueDark">
              Run your business
              <br className="onlyMobile" />
              {' '}
              on the go with
              <br className="onlyMobile" />
              {' '}
              <span className="blueAN">Astro</span>
            </h1>
            <h4 className="txt blueAN mt4">
              Book more customers. Get paid instantly. Organize your team.
              <br className="onlyDesktop" />
              All in one
              {' '}
              <br className="onlyMobile" />
              easy app.
            </h4>
          </div>
          <div className="containerImg col-12 col-lg-4">
            <Cover />
          </div>
        </div>
      </div>
      <div className="containerA features alignTC mt2">
        <h2 className="genericTitle blueDark">
          Get started with Astro,
          <br className="onlyMobile" />
          {' '}
          and enjoy mission-control
          <br />
          {' '}
          for your business
        </h2>
        <div className="containerFeatures">
          <div className="blockFeature row" style={{ flexDirection: 'row-reverse' }}>
            <div
              className="img containerImg col-12 col-md-7"
              style={{ justifyContent: 'flex-end' }}
            >
              <Bookings />
            </div>
            <div className="contentFeature col-12 col-md-5 alignTL">
              <h3 className="genericTitle">Bookings</h3>
              <p className="txt gray">
                Add bookings to your schedule in a few seconds.
                {' '}
                Or send your customers a mobile friendly booking link.
              </p>
            </div>
          </div>
          <div className="blockFeature row" style={{ flexDirection: 'row' }}>
            <div
              className="img containerImg col-12 col-md-7"
              style={{ justifyContent: 'flex-start' }}
            >
              <TeamManagement />
            </div>
            <div className="contentFeature col-12 col-md-5 alignTL">
              <h3 className="genericTitle">Team Management</h3>
              <p className="txt gray">
                Streamline your day to day. Assign bookings to your team, and get
                <br />
                real-time updates.
              </p>
            </div>
          </div>
          <div className="blockFeature row" style={{ flexDirection: 'row-reverse' }}>
            <div
              className="img containerImg col-12 col-md-7"
              style={{ justifyContent: 'flex-end' }}
            >
              <Chat />
            </div>
            <div className="contentFeature col-12 col-md-5 alignTL">
              <h3 className="genericTitle">Chat</h3>
              <p className="txt gray">
                Reduce the back-and-forth.
                <br />
                {' '}
                Chat with your customers straight
                <br />
                {' '}
                from our app, and send them automated notifications.
              </p>
            </div>
          </div>
          <div className="blockFeature row" style={{ flexDirection: 'row' }}>
            <div
              className="img containerImg col-12 col-md-7"
              style={{ justifyContent: 'flex-start' }}
            >
              <Payments />
            </div>
            <div className="contentFeature col-12 col-md-5 alignTL">
              <h3 className="genericTitle">Payments</h3>
              <p className="txt gray">Get paid instantly with Paypal, Venmo or Apple Pay.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid join alignTC" id="kf">
        <h2 className="genericTitle">
          Astro was acquired by
          {' '}
          <a
            href="https://konfio.mx"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#22262a', 'text-decoration': 'underline' }}
          >
            Konfío
          </a>
          {' '}
          in December 2019.
          <br />
          <br />
          We are truly grateful to our customers and
          <br />
          partners for your support on this journey.
        </h2>
      </div>
    </div>
  </section>
);

export default Home;
