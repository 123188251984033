/* eslint-disable max-len */
import React from 'react';

const Chat = () => (
  <svg className="phoneScheduler imgSquare" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="453" viewBox="0 0 453 676">
    <defs>
      <filter id="as" width="104.2%" height="102.1%" x="-2.1%" y="-1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <rect id="cs" width="288" height="576" rx="40" />
      <filter id="bs" width="160.8%" height="130.4%" x="-21.7%" y="-10.9%" filterUnits="objectBoundingBox">
        <feOffset dx="25" dy="25" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="25" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.463567183 0 0 0 0 0.463567183 0 0 0 0 0.466836735 0 0 0 0.495782382 0" />
      </filter>
      <filter id="ds" width="142.7%" height="121.4%" x="-12.7%" y="-6.3%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="11" />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.1 0" />
      </filter>
      <rect id="fs" width="253" height="545" x="18" y="16" rx="25" />
      <filter id="es" width="111.9%" height="105.5%" x="-5.9%" y="-2.8%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.0480355525 0" />
      </filter>
      <filter id="gs" width="122.1%" height="195.8%" x="-11.1%" y="-47.9%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hs" width="122.1%" height="195.8%" x="-11.1%" y="-47.9%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="is" width="122.1%" height="195.8%" x="-11.1%" y="-47.9%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278431373 0 0 0 0.1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <rect id="ks" width="221" height="161" rx="24" />
      <filter id="js" width="114.5%" height="119.9%" x="-7.2%" y="-8.7%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(90 25)">
        <rect width="288" height="576" x="1" y="2" fill="#000" filter="url(#as)" opacity=".197" rx="40" />
        <use fill="#000" filter="url(#bs)" xlinkHref="#cs" />
        <use fill="#F2F5F8" xlinkHref="#cs" />
        <use fill="#000" filter="url(#ds)" xlinkHref="#cs" />
        <g>
          <use fill="#000" filter="url(#es)" xlinkHref="#fs" />
          <use fill="#F7F7F7" xlinkHref="#fs" />
        </g>
      </g>
      <path fill="#E3E3E3" d="M124 101h221v1H124z" />
      <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500" transform="translate(30 40)">
        <tspan x="183.726" y="38">Assign</tspan>
      </text>
      <g transform="translate(10 133)">
        <g filter="url(#gs)">
          <rect width="208" height="48" fill="#FFF" rx="24" />
          <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
            <tspan x="24" y="30">John Smith</tspan>
          </text>
          <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="32">
            <tspan x="170" y="36">+</tspan>
          </text>
        </g>
        <g filter="url(#hs)" transform="translate(0 128)">
          <rect width="208" height="48" fill="#FFF" rx="24" />
          <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
            <tspan x="24" y="30">Mani Farhang</tspan>
          </text>
          <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="32">
            <tspan x="170" y="36">+</tspan>
          </text>
        </g>
        <path className="lineConnect" stroke="#FF403B" strokeWidth="2" d="M169.498 88c52.567-4.81 89.198 1.524 109.895 19 33.75 28.5-28.161 73.095-51.593 105-29.867 40.667-14.467 107.667 46.2 201" />
        <circle cx="285" cy="114" r="4" fill="#FF403B" />
        <circle cx="229" cy="211" r="4" fill="#FF403B" />
        <g filter="url(#is)" transform="translate(30 64)">
          <rect width="208" height="48" fill="#2D3EE0" rx="24" />
          <text fill="#FFF" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
            <tspan x="24" y="30">Frank Romero</tspan>
          </text>
          <text fill="#FFF" fontFamily="WorkSans-Regular, Work Sans" fontSize="32">
            <tspan x="170" y="36">+</tspan>
          </text>
        </g>
        <g transform="translate(114 275)">
          <use fill="#000" filter="url(#js)" xlinkHref="#ks" />
          <use fill="#FFF" xlinkHref="#ks" />
        </g>
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500" transform="translate(114 275)">
          <tspan x="16" y="30">Order No. 36</tspan>
        </text>
        <text fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(114 275)">
          <tspan x="16" y="77" fill="#9B9B9B">Lockout</tspan>
          {' '}
          <tspan x="16" y="93" fill="#9B9B9B">Sep 30, 4:00 PM</tspan>
          {' '}
          <tspan x="16" y="125" fill="#9B9B9B">Frank Romero</tspan>
          {' '}
          <tspan x="16" y="141" fill="#2D3EE0">frank@heyastro.io</tspan>
        </text>
        <path fill="#E3E3E3" d="M130 323h189v1H130z" />
        <g transform="translate(271 250)">
          <circle className="circleGreen" cx="24" cy="24" r="24" fill="#07F2BB" />
          <path className="lineCheck" stroke="#000047" strokeWidth="2.5" d="M14.667 24.212l5.893 5.894 12.773-12.773" />
        </g>
      </g>
    </g>
  </svg>
);

export default Chat;
