/* eslint-disable max-len */
import React from 'react';

const Payments = () => (
  <svg className="imgSquare phoneChat" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="378" height="560" viewBox="0 0 388 506">
    <defs>
      <path id="bz" d="M208 80l32 32H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0h160c13.255 0 24 10.745 24 24v56z" />
      <filter id="az" width="113.3%" height="128.6%" x="-6.7%" y="-12.5%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <path id="dz" d="M32 80H0l32-32V24C32 10.745 42.745 0 56 0h160c13.255 0 24 10.745 24 24v32c0 13.255-10.745 24-24 24H32z" />
      <filter id="cz" width="113.3%" height="140%" x="-6.7%" y="-17.5%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <path id="fz" d="M208 96l32 32H24c-13.255 0-24-10.745-24-24V24C0 10.745 10.745 0 24 0h160c13.255 0 24 10.745 24 24v72z" />
      <filter id="ez" width="113.3%" height="125%" x="-6.7%" y="-10.9%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(10 382)">
        <use className="chatCard" fill="#000" filter="url(#az)" xlinkHref="#bz" />
        <use className="chatCard" fill="#FFF" xlinkHref="#bz" />
      </g>
      <text className="textChat" fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(10 382)">
        <tspan x="24" y="36">Hey Leo! Here’s your link </tspan>
        {' '}
        <tspan x="24" y="52">to pay, whenever you’re </tspan>
        {' '}
        <tspan x="24" y="68">ready:</tspan>
        {' '}
        <tspan x="24" y="84" textDecoration="underline">http://bit.ly/2oCuBd1</tspan>
      </text>
      <g transform="translate(138 246)">
        <use fill="#000" filter="url(#cz)" xlinkHref="#dz" />
        <use fill="#000047" xlinkHref="#dz" />
      </g>
      <text fill="#FFF" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(138 246)">
        <tspan x="56" y="36">Thanks! I will be waiting </tspan>
        {' '}
        <tspan x="56" y="52">for you.</tspan>
      </text>
      <g transform="translate(10 62)">
        <use fill="#000" filter="url(#ez)" xlinkHref="#fz" />
        <use fill="#FFF" xlinkHref="#fz" />
      </g>
      <text fill="#000047" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(10 62)">
        <tspan x="24" y="36">Hey Leo! Thanks for </tspan>
        {' '}
        <tspan x="24" y="52">choosing us. We have </tspan>
        {' '}
        <tspan x="24" y="68">received your request</tspan>
        {' '}
        <tspan x="24" y="84">and will get back to you</tspan>
        {' '}
        <tspan x="24" y="100">shortly.</tspan>
      </text>
      <path fill="#E3E3E3" d="M10 37h368v1H10z" />
      <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500" transform="translate(10 0)">
        <tspan x="132.286" y="10">Leo S Messages</tspan>
      </text>
      <g transform="translate(258 140)">
        <circle cx="24" cy="24" r="24" fill="#2D3EE0" />
        <text fill="#FFF" fontFamily="Avant" fontSize="16" fontWeight="500">
          <tspan x="17.14" y="27">M</tspan>
        </text>
      </g>
      <g className="circleChat" transform="translate(258 446)">
        <circle cx="24" cy="24" r="24" fill="#2D3EE0" />
        <text fill="#FFF" fontFamily="Avant" fontSize="16" fontWeight="500">
          <tspan x="17.14" y="27">M</tspan>
        </text>
      </g>
      <g transform="translate(60 280)">
        <circle cx="24" cy="24" r="24" fill="#000047" />
        <text fill="#FFF" fontFamily="Avant" fontSize="16" fontWeight="500">
          <tspan x="21.232" y="27">L</tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default Payments;
