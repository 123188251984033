/* eslint-disable max-len */
import React from 'react';

const TeamManagement = () => (
  <svg className="imgSquare phoneBooking" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="378" height="560" viewBox="0 0 388 570">
    <defs>
      <rect id="bw" width="221" height="265" rx="24" />
      <filter id="aw" width="114.5%" height="112.1%" x="-7.2%" y="-5.3%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <rect id="dw" width="221" height="129" rx="24" />
      <filter id="cw" width="114.5%" height="124.8%" x="-7.2%" y="-10.9%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <rect id="fw" width="221" height="112" rx="24" />
      <filter id="ew" width="114.5%" height="128.6%" x="-7.2%" y="-12.5%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <text fill="#2D3EE0" fontFamily="Avant" fontSize="24" fontWeight="500" transform="translate(10 -12)">
        <tspan x="0" y="83">Sep 25, 2019</tspan>
        {' '}
        <tspan x="0" y="115">8 AM</tspan>
      </text>
      <g transform="translate(10 133)">
        <use fill="#000" filter="url(#aw)" xlinkHref="#bw" />
        <use fill="#FFF" xlinkHref="#bw" />
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500">
          <tspan x="42" y="75">A</tspan>
        </text>
        <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500">
          <tspan x="42" y="187">B</tspan>
        </text>
        <path fill="#E3E3E3" d="M16 49h189v-1H16z" />
        <text fill="#9B9B9B" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="42" y="101">Drive Avenue 346</tspan>
          {' '}
          <tspan x="42" y="117">Floor 4</tspan>
          {' '}
          <tspan x="42" y="133">NY</tspan>
        </text>
        <text fill="#9B9B9B" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="42" y="213">Sun Street 56</tspan>
          {' '}
          <tspan x="42" y="229">Floor 1</tspan>
          {' '}
          <tspan x="42" y="245">NY</tspan>
        </text>
        <text fill="#2D3EE0" fontFamily="WorkSans-Regular, Work Sans" fontSize="13">
          <tspan x="16" y="30">Locations</tspan>
        </text>
        <path className="lineLoc" fill="#E3E3E3" d="M21 186V75h1v111z" />
        <circle className="circleA" cx="21" cy="75" r="5" fill="#FF403B" />
        <circle className="circleB" cx="21" cy="186" r="5" fill="#07F2BB" />
      </g>
      <g transform="translate(157 301)">
        <use fill="#000" filter="url(#cw)" xlinkHref="#dw" />
        <use fill="#000047" xlinkHref="#dw" />
      </g>
      <text fill="#FFF" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(157 301)">
        <tspan x="16" y="77">Cris Smith</tspan>
        {' '}
        <tspan x="16" y="93" textDecoration="underline">456 7246385</tspan>
        {' '}
        <tspan x="16" y="109" textDecoration="underline">white@me.com</tspan>
      </text>
      <path fill="#2D3EE0" d="M173 350h189v-1H173z" opacity=".5" />
      <text fill="#FFF" fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(157 301)">
        <tspan x="16" y="30">Pickup and Delivery</tspan>
      </text>
      <g>
        <g transform="translate(157 446)">
          <use fill="#000" filter="url(#ew)" xlinkHref="#fw" />
          <use fill="#FFF" xlinkHref="#fw" />
        </g>
        <text fontFamily="WorkSans-Regular, Work Sans" fontSize="13" transform="translate(157 446)">
          <tspan x="16" y="28" fill="#000047">Instructions: </tspan>
          {' '}
          <tspan x="100.864" y="28" fill="#9B9B9B">Open the door </tspan>
          {' '}
          <tspan x="16" y="44" fill="#9B9B9B">with the code 25.</tspan>
          {' '}
          <tspan x="16" y="76" fill="#000047">Describe the item: </tspan>
          {' '}
          <tspan x="138.655" y="76" fill="#9B9B9B">Yellow </tspan>
          {' '}
          <tspan x="16" y="92" fill="#9B9B9B">box, 35 in x 78 in.</tspan>
        </text>
      </g>
      <path fill="#E3E3E3" d="M10 36h368v1H10z" />
      <text fill="#000047" fontFamily="Avant" fontSize="13" fontWeight="500" transform="translate(10 0)">
        <tspan x="132.748" y="10">New Customer</tspan>
      </text>
    </g>
  </svg>
);

export default TeamManagement;
