import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import Home from './pages/Home';
import Team from './pages/Team';

function App() {
    return (
      <div className="App">
        <header id="layout-header">
          <div id="header" className="header">
            <nav className="containerA navbar navbar-expand-md navbar-light">
              <a className="imageLogo" href="/">
                <img src="assets/astro-logo.png" alt="Astro" />
              </a>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarAstro"
                aria-controls="navbarAstro"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarAstro">
                <ul className=" navbar-nav ml-md-auto">
                  <li className="nav-item">
                    <a href="/team" className="btn btnBlog">Team</a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://konfio.mx"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btnBlog"
                    >
                      Konfío
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/team">
              <Team />
            </Route>
            <Route path="*">
              <p>
                404
              </p>
            </Route>
          </Switch>
        </Router>
        <footer id="layout-footer">
          <div className="container-fluid footer">
            <div className="containerA">
              <p className="white">
                <span id="copyright">
                  {new Date().getFullYear()}
                </span>
                {' '}
                © heyastro corporation
              </p>
              <div className="containerNetworks">
                <a href="https://www.facebook.com/heyastro.io/">
                  <span>
                    <i className="fab fa-facebook-f icon-faceb white" />
                  </span>
                </a>
                <a href="https://www.linkedin.com/company/heyastro/about/">
                  <span>
                    <i className="fab fa-linkedin-in icon-twitter white" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
}

export default App;
